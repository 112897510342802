<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>

        <div style="height:calc(100vh - 3rem - 1rem);">

        </div>


        <!-- <div class="yszbListbox">

            <div class="yszbListLeft">

                <div class="yszbItem">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Image_1684376599509.png" class="yszbItemImg">
                    <div class="yszbItemTxtBox">
                        <p class="yszbItemTxt">
                            <span class="yszbItemSpan">特价活动</span>
                            流浪地球2合金战车，运输车模型
                            流浪地球2合金战车，运输车模型
                        </p>
                    </div>
                </div>

            </div>


            <div class="yszbListRight">

                <div class="yszbItem">
                    <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Image_1684376599509.png" class="yszbItemImg">
                    <div class="yszbItemTxtBox">
                        <p class="yszbItemTxt">
                            <span class="yszbItemSpan">特价活动</span>
                            流浪地球2合金战车，运输车模型
                            
                        </p>
                    </div>
                </div>

            </div>
            

        </div> -->





        <Foot />

        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>


    </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn} from "@/api/index"
import { Loading ,Message } from 'element-ui'
import store from '@/store'
export default {
    components:{
        Foot
    },
    data () {
        return {
            headerflag : false,
            hiddenflag : false,
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
            value1:"",
            columnData:null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            var url = this.$route.path


        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        changeDate(){
            console.log(this.value1)
        }
    },
    mounted(){
        
        this.showLoading()

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // meta.content = {
        //     keywords:"新闻资讯-影视资讯-湖北引未来影视",
        //     description:"新闻资讯-影视资讯-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "新闻资讯-影视资讯-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        ////window.location.href="http://www.zygart.cn"; 
       
        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
            this.load && this.load.close();
        }else{
            getListColumn().then(res => {
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)

                this.load && this.load.close();


            })
        }


        // listArticle({
        //     "data": {
        //         "categoryId": 3,
        //     },
        //     "mapParam": {},
        //     "reqPage": {
        //         "currPageNo": this.currPageNo,
        //         "pageSize": this.pageSize
        //     }
        // }).then(res => {
        //     this.articlelist = res.records
        //     this.total = res.total
            // this.load && this.load.close();
        //})


    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>




<style lang="scss" scoped>
    .yszbListbox{
        width: 100%;
        padding: .26rem .3rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #F6F6F6;
        border-top: 1px solid rgba(112, 112, 112, .2);
        .yszbItem{
            width: 3.36rem;
            background: #FFFFFF;
            border-radius: .18rem;
            overflow: hidden;
            margin-bottom: .28rem;
            .yszbItemImg{
                width: 3.36rem;
                height: 3.36rem;
                display: block;
                object-fit: cover;
            }
            .yszbItemTxtBox{
                padding: .2rem;
                box-sizing: border-box;
                .yszbItemTxt{
                    font-size: .28rem;
                    color: #343434;
                    // display: -webkit-box;    
                    // -webkit-box-orient: vertical;    
                    // -webkit-line-clamp: 2;    
                    // overflow: hidden;
                    .yszbItemSpan{
                        display: inline-table;
                        padding: 0.02rem 0.1rem;
                        background: #A30001;
                        border-radius: .08rem;
                        font-size: .24rem;
                        color: #FFFFFF;
                        transform: scale(.92);
                    }
                }
            }
        }
    }




</style>